import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ArtData } from '../utils/ArtData';
import { Link } from 'react-router-dom';
import { Routes } from '../utils/Routes';
import LayOut from '../components/common/Layout';
import Heading from '../components/common/Heading';
import { PricingInfo } from '../components/common/PricingInfo';
import ArtPlaceholder from '../assets/images/Art/ArtPlaceholder.svg';
import MusicCard from '../components/unspokenMusic/MusicCard';
import musicMetadata from '../assets/music/metadata.json';
import NFTIcon from '../assets/images/nft_icon.png';

export default function ArtDetails() {
  const { id } = useParams<{ id: string }>(); // Use id from the URL

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Find corresponding art collection based on id (case-insensitive)
  const artCollection = ArtData.find(
    (art) => art.metadata.id?.toLowerCase() === id?.toLowerCase()
  );

  // Find corresponding music metadata
  const musicMeta = musicMetadata.find(
    (music) => music.filename === artCollection?.metadata.music
  );

  // Provide default values for title and artist if they are missing
  const musicTitle = musicMeta?.title || 'Untitled Soundtrack';
  const musicArtist = musicMeta?.artist || 'Unknown Artist';

  // State for controlling the current image in the carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // State for tracking if the current image has loaded
  const [loadedImages, setLoadedImages] = useState(
    Array(artCollection?.images.length).fill(false)
  );

  // State to track whether the image is in full-screen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  // States to track touch start and end positions for swipe detection
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // Preload the next image in the array for smoother transitions
  useEffect(() => {
    if (artCollection && currentImageIndex < artCollection.images.length - 1) {
      const nextImage = new Image();
      nextImage.src = artCollection.images[currentImageIndex + 1];
    }
  }, [currentImageIndex, artCollection]);

  // Disable scrolling when in full-screen mode
  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isFullScreen]);

  if (
    !artCollection ||
    !artCollection.images ||
    artCollection.images.length === 0
  ) {
    return (
      <LayOut layoutStyle="bg-white">
        <section className="flex flex-col items-center p-5">
          <div className="container mx-auto my-8 p-4">
            {/* Back to Gallery Link */}
            <div className="text-left mb-6">
              <Link
                to={Routes.Art}
                className="text-blue-400 hover:text-blue-700 underline text-lg inline-flex items-center"
              >
                &#8592; Back to Art Gallery
              </Link>
            </div>
            <Heading
              customStyle="text-md text-gray-400 text-center"
              text="Art Collection Not Found!"
            />
          </div>
        </section>
      </LayOut>
    );
  }

  const { images, metadata } = artCollection;

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Handle image load event for a specific index
  const handleImageLoad = (index: number) => {
    const updatedLoadedImages = [...loadedImages];
    updatedLoadedImages[index] = true;
    setLoadedImages(updatedLoadedImages);
  };

  // Touch event handlers to track swipe gesture
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const swipeThreshold = 50; // Minimum swipe distance to trigger an action

    if (distance > swipeThreshold) {
      // Swiped left, move to the next image
      handleNext();
    } else if (distance < -swipeThreshold) {
      // Swiped right, move to the previous image
      handlePrevious();
    }

    // Reset the touch start and end states
    setTouchStart(null);
    setTouchEnd(null);
  };

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <LayOut layoutStyle="bg-white">
      <section className="flex flex-col items-center p-5">
        <div className="container mx-auto my-8 p-4">
          {/* Back to Gallery Link */}
          <div className="text-left mb-6">
            <Link
              to={`${Routes.Art}?id=${metadata.id}`}
              className="text-blue-400 hover:text-blue-700 underline text-lg inline-flex items-center"
            >
              &#8592; Back to Art Gallery
            </Link>
          </div>

          {/* Carousel and Metadata */}
          <div className="flex flex-col lg:flex-row lg:justify-between items-center">
            {/* Carousel Section (with swipe and full-screen toggle) */}
            <div
              className="flex flex-col items-center lg:w-1/2 mx-auto"
              onTouchStart={handleTouchStart} // Start tracking the touch
              onTouchMove={handleTouchMove} // Track touch movement
              onTouchEnd={handleTouchEnd} // Handle swipe when touch ends
            >
              <h2 className="md:hidden text-2xl font-semibold mb-4">
                {metadata.title}
              </h2>
              <div
                className="w-full p-4 border border-gray-300 shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out max-w-md cursor-pointer"
                onClick={handleFullScreenToggle}
              >
                {/* Show placeholder image if not loaded */}
                {!loadedImages[currentImageIndex] && (
                  <img
                    src={ArtPlaceholder}
                    alt="Art Placeholder"
                    className="w-full h-auto rounded-lg"
                  />
                )}
                {/* Main image */}
                <img
                  src={images[currentImageIndex]}
                  alt={`Artwork: ${metadata.title} - Slide ${
                    currentImageIndex + 1
                  }`}
                  className={`w-full h-auto rounded-lg transition-opacity duration-500 ${
                    loadedImages[currentImageIndex] ? 'block' : 'hidden'
                  }`}
                  onLoad={() => handleImageLoad(currentImageIndex)}
                />
              </div>

              {/* Navigation and Dotted Indicator */}
              <div className="flex items-center justify-center mt-4 space-x-4">
                {/* Previous Button */}
                <button
                  onClick={handlePrevious}
                  className="bg-gray-200 px-4 py-2 rounded-full shadow hover:bg-gray-300 transition"
                >
                  &#8592;
                </button>

                {/* Dotted Indicator */}
                <div className="flex space-x-2">
                  {images.map((_, index) => (
                    <div
                      key={index}
                      className={`w-3 h-3 rounded-full ${
                        currentImageIndex === index
                          ? 'bg-red-600'
                          : 'bg-gray-300'
                      }`}
                    />
                  ))}
                </div>

                {/* Next Button */}
                <button
                  onClick={handleNext}
                  className="bg-gray-200 px-4 py-2 rounded-full shadow hover:bg-gray-300 transition"
                >
                  &#8594;
                </button>
              </div>
            </div>

            {/* Art Info Section */}
            <div className="mt-6 lg:mt-0 lg:ml-8 lg:w-1/2 text-center lg:text-left">
              <h2 className="hidden md:block text-2xl font-semibold mb-4">
                {metadata.title}
              </h2>
              <div className="mb-6 text-gray-600 text-lg">
                <p className="mb-2">
                  <strong>Artist:</strong> {metadata.artist || 'Anonymous'}
                </p>
                {metadata.medium && (
                  <p className="text-base text-gray-500 mb-2">
                    <strong>Medium:</strong> {metadata.medium}
                  </p>
                )}
                {metadata.style && (
                  <p className="text-base text-gray-500 mb-2">
                    <strong>Style:</strong> {metadata.style}
                  </p>
                )}
                {metadata.year && (
                  <p className="text-base text-gray-500 mb-2">
                    <strong>Year:</strong> {metadata.year}
                  </p>
                )}
                {metadata.dimensions?.width && metadata.dimensions?.height && (
                  <p className="text-base text-gray-500 mb-2">
                    <strong>Dimensions:</strong> {metadata.dimensions.width} x{' '}
                    {metadata.dimensions.height}
                  </p>
                )}
              </div>
              {metadata.description && (
                <div className="p-4 bg-gray-50 rounded-lg shadow-inner text-left">
                  <p className="text-gray-700">{metadata.description}</p>
                </div>
              )}
              {metadata.music && musicMeta && (
                <div className="mt-2">
                  <MusicCard
                    musicFileName={musicMeta.filename}
                    title={musicTitle}
                    artist={musicArtist}
                  />
                </div>
              )}
              <div className="pt-5">
                {/* Render the PricingInfo */}
                <PricingInfo metadata={metadata} />
              </div>
              {/* NFT */}
              {metadata.nft && (
                <div className="pt-5 border-t border-gray-300 mt-5 text-left">
                  <a
                    href={metadata.nft}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block relative group"
                  >
                    <img
                      src={NFTIcon}
                      alt="View NFT"
                      className="w-12 h-12 mx-auto transition-transform transform group-hover:scale-110"
                    />
                    <span className="absolute inset-0 bg-blue-500/10 rounded-full opacity-0 group-hover:opacity-100 transition duration-300"></span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Full-Screen Image View */}
      {isFullScreen && (
        <div className="fixed inset-0 bg-gray-800 z-50 flex justify-center items-center">
          {/* Full-Screen Image */}
          <img
            src={images[currentImageIndex]}
            alt={metadata.title}
            className="w-auto h-auto max-w-full max-h-full"
          />

          {/* Navigation Controls */}
          <button
            onClick={handlePrevious}
            className="absolute left-5 top-1/2 transform -translate-y-1/2 text-3xl"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              className="bg-black rounded-full hover:bg-gray-700 transition-colors duration-300 p-2"
            >
              <circle cx="20" cy="20" r="20" fill="currentColor" />
              <line
                x1="27"
                y1="20"
                x2="13"
                y2="20"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <polyline
                points="20 13, 13 20, 20 27"
                fill="none"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className="absolute right-5 top-1/2 transform -translate-y-1/2 text-3xl"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              className="bg-black rounded-full hover:bg-gray-700 transition-colors duration-300 p-2"
            >
              <circle cx="20" cy="20" r="20" fill="currentColor" />
              <line
                x1="13"
                y1="20"
                x2="27"
                y2="20"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <polyline
                points="20 13, 27 20, 20 27"
                fill="none"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          {/* Close Button */}
          <button
            onClick={handleFullScreenToggle}
            className="absolute top-5 right-5 text-gray-300 text-3xl hover:text-red-600 transition-colors duration-300"
          >
            &#10005;
          </button>
        </div>
      )}
    </LayOut>
  );
}
