// Art Info
const InfoContent = () => {
  return (
    <div className="text-left">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">
        The Currency of Existence
      </h2>
      <div className="text-lg text-gray-700 flex flex-col gap-2">
        <p>
          In this age of unrelenting noise, attention has become the most
          precious resource. It is finite, intensely personal and undeniably
          transformative. Yet, it is often bartered away — commodified, bought
          and sold by forces that vie for control over the human mind. "Pay
          Attention" beckons you to ponder this profound reality, to examine the
          choices that shape the architecture of your focus.
        </p>
        <b className="mt-5">The Power of Focus</b>
        <p>
          To focus is to create. It is the silent architect of worlds, the seed
          from which movements arise and the prism through which meaning is
          refracted. Directed attention has the power to ignite revolutions,
          mend fractured relationships and illuminate the joy hidden in the
          ordinary. In a distracted world, focus is a revolutionary act — a
          reclamation of self against the encroaching tide of noise.
        </p>
        <p>
          Attention is more than observation; it is an affirmation of value and
          purpose. Through it, we do not merely see the world; we construct it.
          What you choose to focus on shapes your reality, defines your
          narrative and colors your existence.
        </p>
        <b className="mt-5">The War for Your Focus</b>
        <p>
          A silent battle rages for your attention. Algorithms, platforms and
          targeted stimuli exploit human vulnerabilities, transmuting your focus
          into currency. Notifications, infinite scrolls and alluring ads are
          not benign conveniences but weapons in this war. Each glance, each
          interaction feeds an insatiable machine, growing ever more adept at
          commandeering your awareness.
        </p>
        <b className="mt-5">A Collective Call</b>
        <p>
          Attention transcends the individual — it is a collective force. Where
          we direct our shared focus, we shape the trajectory of culture,
          society and humanity itself. Misplaced attention amplifies the
          trivial, while the critical withers in obscurity. To reclaim our
          collective focus is to restore balance to the narratives that define
          us.
        </p>
        <b className="mt-5">Final Reflection</b>
        <p>
          To pay attention is to reclaim your agency. It is an act of defiance
          in a world designed to steal your focus. Choose wisely, for every
          moment spent is a declaration of what matters. Attention is the
          alchemy of existence — where it flows, reality is forged.
        </p>
      </div>
      <blockquote className="border-l-4 border-yellow-500 pl-4 italic text-gray-500 mt-10">
        "Distraction is the erosion of identity, the dissolution of self into
        the abyss of noise."
      </blockquote>
    </div>
  );
};

export default InfoContent;
