// Art Info
const InfoContent = () => {
  return (
    <div className="text-left">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">
        The Pursuit of Shadows
      </h2>
      <div className="text-lg text-gray-700 flex flex-col gap-2">
        <b></b>
        <p>
        We are participants, drawn into a dance of illusions, reaching for what dazzles, what promises fulfillment, yet fades as we approach.
        The spectacle thrives on our desire to chase. It shows us images of beauty, success, meaning — mirages that shimmer on the horizon. Yet the closer we get, the further they recede. 

        </p>
        <p>
          We are flooded with noise so we do not hear the silence.
          So what are we being distracted from?
        </p>
      </div>
      <blockquote className="border-l-4 border-yellow-500 pl-4 italic text-gray-500 mt-10">
        What you desire is not chosen by you. It is given to you, sold to you,
        fed to you until it becomes your own.
      </blockquote>
    </div>
  );
};

export default InfoContent;
