// Chasing Ghosts Metadata.
import ChasingGhostsSVG from '../../../assets/images/Thoughts/ChasingGhosts.svg';
import InfoContent from './InfoContent';

const ChasingGhostsMetadata = {
  id: 'ChasingGhosts',
  title: 'Chasing Ghosts',
  description: 'The Pursuit of Shadows',
  thumbnail: ChasingGhostsSVG,
  featured: false,
  info: InfoContent,
};

export default ChasingGhostsMetadata;
