// Pay Attention Metadata.

import InfoContent from './InfoContent';

const PayAttentionMetadata = {
  id: 'PayAttention',
  title: 'Pay Attention',
  description: 'The Currency of Existence',
  featured: false,
  info: InfoContent,
};

export default PayAttentionMetadata;
