// Observed Metadata.
import ObservedSVG from '../../../assets/images/Thoughts/Observed.svg';
import InfoContent from './InfoContent';

const HappySadMetadata = {
  id: 'Observed',
  title: 'Can you see me?',
  description: 'The Weight of Being Watched',
  thumbnail: ObservedSVG,
  featured: true,
  info: InfoContent,
};

export default HappySadMetadata;
