import LayOut from '../components/common/Layout';
import backgroundImage from '../assets/images/background_image.jpg';
import { Button } from '../components/common/Button';
import { Link } from 'react-router-dom';
import { Routes } from '../utils/Routes';
import { useEffect, useState } from 'react';
import FeaturedArt from '../components/common/FeaturedArt';
import BinarySequence from '../components/common/BinarySequence';

export default function Home() {
  const [showSpecialMessage, setShowSpecialMessage] = useState(false);
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    setCurrentTime(formattedTime);

    // Check if the current time is between 3:00 AM and 3:15 AM
    setShowSpecialMessage(hours === 3 && minutes >= 0 && minutes < 15);
  }, []);

  return (
    <LayOut>
      {/* Unified Section */}
      <div
        className="flex flex-col items-center gap-12 bg-cover bg-center bg-no-repeat py-16 px-8"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {/* Hero Content */}
        <div className="flex flex-col items-center text-center text-white gap-6 w-full">
          <BinarySequence />
          {showSpecialMessage && (
            <div className="mt-4 bg-gray-800 text-yellow-400 px-6 py-3 rounded-lg shadow-md">
              <p className="text-sm md:text-lg font-medium italic">
                It's {currentTime}... Why are you still awake?
              </p>
            </div>
          )}
          <div className="flex flex-wrap justify-center gap-6 mt-6">
            <Link to={Routes.Art}>
              <Button
                label="Unspoken Art"
                className="bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-black hover:to-transparent font-semibold px-8 py-3 rounded-full shadow-md transition-transform hover:scale-105"
              />
            </Link>
            <Link to={Routes.Thoughts}>
              <Button
                label="Unspoken Thoughts"
                className="bg-gradient-to-r from-gray-600 to-gray-400 hover:from-black hover:to-transparent font-semibold px-8 py-3 rounded-full shadow-md transition-transform hover:scale-105"
              />
            </Link>
            <Link to={Routes.Music}>
              <Button
                label="Unspoken Music"
                className="bg-gradient-to-r from-blue-400 to-indigo-500 hover:from-black hover:to-transparent font-semibold px-8 py-3 rounded-full shadow-md transition-transform hover:scale-105"
              />
            </Link>
          </div>
        </div>

        {/* Featured Art Section */}
        <FeaturedArt />
      </div>
    </LayOut>
  );
}
